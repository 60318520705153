<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <div class="col-lg-12">  
                <div class="card">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <v-col class="col-6" sm="6" md="2" lg="1">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Type</h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-0" hide-details=true v-model="typeModel" 
                                     :items="typeLists" default="" item-value="value" item-text="text" label="Type"
                                      clearable>
                                </v-autocomplete>
                            </v-col> 
                             <v-col class="col-6" sm="6" md="2" lg="1">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Region </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-0" hide-details=true v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" :label=labelRegion clearable  :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)">
                                </v-autocomplete>
                            </v-col>  
                            <v-col class="col-6" sm="6" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Branch Office</h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-0" hide-details=true v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch clearable :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)">
                                </v-autocomplete>
                            </v-col>  
                            <v-col class="col-6" sm="6" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Sales</h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-0" hide-details=true v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales clearable :disabled="disabled_salesman == true" >
                                </v-autocomplete>
                            </v-col>  
                            <v-col class="col-6" sm="6" md="2" lg="1"> 
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Allow Shipment</h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-0" hide-details=true  v-model="shipFlagModel" :items="shipFlagLists" default="" item-value="value" item-text="text" label="ALLOW SHIPMENT" clearable>
                                </v-autocomplete>
                            </v-col> 
                            <!-- <v-col cols="12" xs="12" sm="3" md="3">
                                <h6 class="blue-lcd mb-1 pa-1">Customer</h6>
                                <v-autocomplete 
                                    dense solo class="ma-0 pa-0" 
                                    v-model="custModel"
                                    :items="custLists"
                                    item-value="CustomerId"
                                    item-text="NamaCustomer"
                                    :search-input.sync="searchCustomer"
                                    clearable   
                                    label="Customer">
                                </v-autocomplete>
                            </v-col>   -->
                            <v-col class="col-6" sm="6" md="3">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Customer </h6> -->
                                <v-autocomplete
                                    dense 
                                    solo 
                                    v-model="custModel"
                                    :items="custLists"
                                    item-value="CustomerId"
                                    item-text="NamaCustomer"
                                    :search-input.sync="searchCustomer"
                                    cache-items
                                    class="ma-0 pa-0"
                                    hide-details=true  
                                    clearable
                                    label="Customer">
                                </v-autocomplete>
                                </v-col>  
                                <v-col class="col-6" sm="6" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">ORDER ID</h6> -->
                                <v-autocomplete 
                                    dense 
                                    solo 
                                    cache-items
                                    class="ma-0 pa-0" 
                                    hide-details=true 
                                    v-model="orderIDModel"
                                    :items="orderIDLists"
                                    item-value="order_id"
                                    item-text="DetailOrder"
                                    :search-input.sync="searchOrderID"
                                    clearable   
                                    label="Order Id">
                                </v-autocomplete>
                            </v-col>  
                                <!-- <div class="col-6 col-lg-2">
                                <v-autocomplete
                                    v-model="orderIDModel"
                                    :items="orderIDLists"
                                    item-value="order_id"
                                    item-text="DetailOrder"
                                    :search-input.sync="searchOrderID"
                                    cache-items
                                    flat
                                    hide-no-data
                                    hide-details
                                    clearable
                                    dense
                                    label="ORDER ID ID"></v-autocomplete>
                                </div>  -->
                            <!-- <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Depo </h6>
                                <v-autocomplete dense solo class="ma-0 pa-0" hide-details=true v-model="millModel"
                                    :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Depo"
                                      clearable>
                                </v-autocomplete>
                            </v-col> -->
                            <v-col class="col-12" sm="6" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Start Date</h6> -->
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date"  class="ma-0 pa-0" solo readonly v-bind="attrs" v-on="on" dense clearable prepend-inner-icon="mdi-calendar-check" hide-details></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">End Date</h6> -->
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" class="ma-0 pa-0" solo readonly v-bind="attrs" v-on="on" dense clearable prepend-inner-icon="mdi-calendar-check" hide-details></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col class="col-12" sm="6" md="4">
                                <v-btn class="mr-3 mt-2" style="padding: 20px;" elevation="2" small @click="clear()">Clear</v-btn>
                                <v-btn class="mt-2" style="padding: 20px;" color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 cardResult">
                <v-col cols="12">
                    <v-card outlined class="p-0" >
                        <v-card-text class="mt-0">
                            <v-data-table
                                dense
                                :headers="headers"
                                :items="itemLists"
                                class="elevation-1 mt-0"
                                :items-per-page="30"
                                height="300"
                                fixed-header
                                :divider="true"
                                :light="true"
                                :search="searchItem"
                                >  
                                <template v-slot:top>
                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                        <div class="d-flex w-100">
                                            <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            small
                                            color="#fff"
                                            class="py-5 mr-3"
                                            @click="getData()"
                                            >
                                                <v-icon>mdi-cached</v-icon>
                                            </v-btn>
                                            <v-btn
                                            small
                                            color="#005c37"
                                            class="py-5 mr-3 text-white"
                                            >
                                            <v-btn class="mb-0 mr-2 text-white" 
                                            color="#005c37" elevation="2" medium>
                                            <download-excel
                                            class="text"
                                            :fetch           = "exportExcel"
                                            :fields="headersColumn"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            To Excel
                                            </download-excel> 
                                            <v-icon>mdi-arrow-down-bold-box</v-icon>
                                        </v-btn>
                                            </v-btn>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-menu
                                    bottom
                                    left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                color="grey"
                                                class="text-center"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="showItemDetails(item)">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-eye</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Detail</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template> 
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </div>
            <v-dialog
            v-model="dialogDetails"
            persistent
            max-width="1200px"
            hide-overlay
            transition="dialog-bottom-transition"
            >
                <v-card class="rounded-l">
                    <v-card-title class="text-center">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <div class="ml-5">
                                        Detail Report
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="[dialogDetails = false]"
                                    >
                                        <v-icon dark>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="ma-0">
                        <v-data-table
                            dense
                            :headers="headersDetails"
                            :items="itemListsDetails"
                            class="elevation-1 mt-3"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="400"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :search="searchItem" 
                            >    
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="mt-0 mb-0">
                                    <div class="d-flex w-100">
                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-spacer></v-spacer>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Ready To Ship',
                disabled: true,
                href: '#',
                },
            ],
            typeModel: '',
            typeLists: [],
            labelRegion: 'Region',
            regionModel: '',
            regionLists: [],
            disabled_region: true,
            labelBranch: 'Branch Office',
            officeModel: '',
            officeLists: [],
            disabled_office: true,
            labelSales: 'Salesman',
            salesModel: '',
            salesLists: [],
            disabled_salesman: true,
            shipFlagModel: '',
            shipFlagLists: [
                {
                    'text' : 'ALL',
                    'value' : ''
                },
                {
                    'text' : 'YES',
                    'value' : 'Y'
                },
                {
                    'text' : 'NO',
                    'value' : 'N'
                }
            ],
            custModel: '',
            custLists: [],
            orderIDModel: '',
            orderIDLists: [],
            problemModel: '',
            problemLists: [
                {'problem_id' : 'Y'}, 
                {'problem_id' : 'N'},
                {'problem_id' : 'A'}
            ],
            dateOrderModal: false,
            dateOrderModel: '',
            dateShipModal: false,
            dateShipModel: '',
            searchCustomer: null,
            searchOrderID: null,
            cardResult : false,
            headers:[
                // { text: 'MILL', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER ID', value: 'order_id', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SOURCE', value: 'mill_source', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'ORDER DATE', value: 'order_date', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'RCV. DATE', value: 'rcv_date', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'REQ. SHIP DATE', value: 'req_ship_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'cust_name', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SHIP ADDRESS', value: 'DikirimKe', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'PAYTERM', value: 'pay_term', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'ALLOW SHIPMENT', value: 'ship_flag', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: '', value: 'action', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
            ],
            headersColumn: {
            'ORDER ID':'order_id',
            'SALESMAN': 'NamaSales',
            'OFFICE': 'office',
            'SOURCE' : 'mill_source',
            'ORDER DATE': 'order_date',
            'RCV. DATE': 'rcv_date',
            'REQ. SHIP DATE': 'req_ship_date',
            'CUSTOMER': 'cust_name',
            'SHIP ADDRESS': 'DikirimKe',
            'PAYTERM': 'pay_term',
            'ALLOW SHIPMENT': 'problem',
            },
            itemLists: [],
            searchItem: '',
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            dateStartFormatted: '',
            dateEndFormatted: '',
            dialogDetails: false,
            headersDetails: [
                // { text: 'MILL', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO.', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'LENGTH', value: 'panjang', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'QTY. ORDER', value: 'qty_order', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'ONHAND', value: 'onHand', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SHIPPED', value: 'qty_ship', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'REPLACE', value: 'qty_replace', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'WGT. ORDER(Kg)', value: 'wgtOrder', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'WGT. ON HAND(Kg)', value: 'wgtOnHand', align: 'right', class: 'primary--text blue lighten-5', sortable: false },
            ],
            itemListsDetails: [],
            detailModalTitle: '',
            detailModalTitle2: '',
            loadingDetails: false
        }
    },
    async mounted(){
        
        
        this.initialize()

    },
    methods:{
        clear(){
            this.typeModel = ''
            this.regionModel = ''
            this.officeModel = ''
            this.salesModel = ''
        //  /   this.shipFlagModel = ''
            this.custModel = ''
            this.orderIDModel = ''
        },
        startDownload(){
           this.loading = true
           this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },
        async exportExcel() {
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/exportExcel?search=${this.$store.state.text}&type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${this.officeModel ? this.officeModel : ''}
            &sales=${this.salesModel ? this.salesModel : ''}&shipFlag=${this.shipFlagModel ? this.shipFlagModel : ''}&customer=${this.custModel ? this.custModel : ''}&orderid=${this.orderIDModel ? this.orderIDModel : ''}&dateFrom=${this.dateStartFormatted ? this.dateStartFormatted : ''}&dateTo=${this.dateEndFormatted ? this.dateEndFormatted : ''}&problem=${this.problemModel ? this.problemModel : ''}`, {
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            });
             console.log(response.data);
             return response.data.result
        },

        async initialize(){
            this.$store.dispatch('setOverlay', true)
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                if(res.data.type == 'R'){

                        this.typeModel = 'PROJECT'
                        this.typeLists = [
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            }
                        ]

                } else {

                        this.typeLists = [
                            {
                                value: '',
                                text: 'All'
                            },
                            {
                                value: 'RETAIL',
                                text: 'RETAIL'
                            },
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            },
                        ]

                }
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
            
        },

        async getData(){

            this.cardResult = false
            this.itemLists = []
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/getData`, { 
                    type: this.typeModel ? this.typeModel : '',
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    shipFlag: this.shipFlagModel ? this.shipFlagModel : '',
                    customer: this.custModel ? this.custModel : '',
                    orderid: this.orderIDModel ? this.orderIDModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                    problem: this.problemModel,
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                if(res.data.result.length != 0){
                    this.cardResult = true
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async regionOnChange(id){
            
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/regionOnChange?type=${ this.typeModel ? this.typeModel : '' }&region=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getCustomer(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/getCustomer`, { 
                    id : id ? id : '',
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.custLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getOrderID(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/getOrderID`, { 
                    id : id ? id : '',
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.orderIDLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async showItemDetails(id)
        {

            this.dialogDetails = true
            this.detailModalTitle = id.order_id
            this.detailModalTitle2 = id.cust_name
            this.loadingDetails = true
            this.itemListsDetails = []
            this.$store.dispatch('setOverlay', true)
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderReadyToShip/getDataDetails`, { 
                    id : id.order_id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.loadingDetails = false
                this.itemListsDetails = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    },
    watch: {
        searchCustomer (val) {
            val && val !== this.custModel && this.getCustomer(val)
        },
        searchOrderID (val) {
            val && val !== this.orderIDModel && this.getOrderID(val)
        },
    },
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>